.container {
    max-width: 100%;
    margin-top: 140px;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description {
    margin-bottom: 66px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 40px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 180px;
    height: 185px;
    background-color: #E8F2FE;
    border-radius: 30px;
    border: 2px solid #FB942C;
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 60px 0;
}

.tab {
    cursor: pointer;
    opacity: 0.5;
}

.tab:hover {
    opacity: 1;
}

.selected {
    opacity: 1;
}

.tab_container {
    width: 100%;
    gap: 65px;
    display: flex;
    margin-bottom: 60px;
}

.tab_container_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card_icon {
    width: 335px;
    height: 211px;
}

.text {
    font-size: 21px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
}

.button {
    background-color: #D62B1E;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    width: fit-content;
    margin-top: 20px;
    cursor: pointer;
}

.accordeon_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
}

.accordeon_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s;
}

.accordeon_selected {
    opacity: 1;
}

.accordeon_plus {
    transition: all 0.3s;
    font-size: 50px;
}

.accordeon_plus_active {
    transform: rotate(45deg);
}

.line {
    height: 1px;
    width: 100%;
    background-color: #07336C;
}

.accordeon_item {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.accordeon_item_active {
    height: 390px;
}

.accordeon_item_info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.accordeon_item_info > p {
    text-align: center;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .container {
        max-width: 100%;
        margin-top: 100px;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .description {
        margin-bottom: 20px;
    }


    .cards {
        gap: 10px;
    }

    .card {
        width: calc(50% - 5px);
        padding: 10px;
    }

    .text {
        font-size: 16px;
    }

    .accordeon_item_active {
        height: 420px;
    }

    .button {
        margin-top: 0;
    }

    .card_description {
        font-size: 26px;
        line-height: 28px;
    }
}
