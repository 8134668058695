.contacts2_first_flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  border-radius: 30px;
  padding: 75px;
  gap: 50px;
  margin: 0 auto;
  margin-top: 90px;
  background: #07336C;
  color: #FFFFFF;
}

.contacts2_first_flex_box1 > h1 {
  color: #FFFFFF;
}

.contacts2_first_flex_box1 > p {
  color: #FFFFFF;
}

.contacts2_second_flex {
  width: 1180px;
  height: 70px;
  border-radius: 30px;
}

.contacts2_first_flex_box1 {
  display: flex;
  justify-content: space-between;
}

.contacts2_first_flex_box1 > h1 {
  width: 715px;
}

.contacts2_first_flex_box1 > p {
  width: 370px;
}

.contacts2_first_flex_inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.contacts2_first_flex_inputs > p {
  color: #FFFFFF;
}

.contacts2_first_flex_inputs_info {
  width: 334px;
  height: 59px;
  background: #FFFFFF;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 30px;
  gap: 10px;
  flex: none;
  order: 1;
  flex-grow: 1;
  border: none;
  outline: none;
}

.contacts2_first_flex_inputs_info:active {
  border: none;
  outline: none;
}

.contacts2_first_flex_inputs_danger {
  color: #FFFFFF;
  width: 334px;
  height: 61px;
  border: none;
  outline: none;
  background: #D62B1E;
  border-radius: 30px;
  flex: none;
  order: 2;
  flex-grow: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: anim 0.1s ease;
}

.contacts_first_flex_inputs_danger {
  color: #FFFFFF;
}

.contacts2_first_flex_inputs_danger:active {
  border: none;
  outline: none;
}

.contacts2_first_flex_inputs_danger:hover {
  background: #EB766D;
}

.contacts2_first_flex > p {
  margin-top: -20px;
  margin-left: -10px;
  text-align: start;
}

.contacts2_first_flex > a {
  cursor: pointer;
}

.error {
  border: 1px solid red;
}

u {
  color: #FFFFFF;
}

/*====================== MOBILE ==================================*/


@media screen and (max-width: 480px) {
  .contacts2_first_flex_inputs {
    flex-wrap: wrap;
  }

  .contacts2_first_flex {
    height: 100%;
    padding: 20px;
  }

  .contacts2_first_flex_inputs_info {
    width: 100%;
  }

  .contacts2_first_flex_box1 > p {
    display: none;
  }

  .contacts2_first_flex_box1 > h1 {
    align-items: center;
    text-align: center;
  }

  .contacts2_first_flex_inputs_danger {
    width: 100%;
    margin-top: 15px;
  }

  .contacts2_first_flex > p {
    text-align: center;
    margin: 0 20px 20px 20px;
  }
}





