.modals_box {
  max-width: 1440px;
  width: 100%;
  background: #07336C;
  display: flex;
  padding: 3%;
  object-fit: fill;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.modals_box_field {
  width: 100%;
  height: 100%;
  object-fit: fill;
  background: #FFFFFF;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.modals_box_header {
  display: flex;
  justify-content: space-between;
}

.modals_box_header > button {
  border: none;
  outline: none;
  background: none;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: anim 0.1s ease;
}

.modals_box_header > button:hover {
  opacity: 1;
}

.modals_box_info {
  display: flex;
  gap: 15px;
}

.modals_box_info > button {
  padding: 10px 15px;
  background: #FFFFFF;
  border: 2px solid #07336C;
  border-radius: 30px;
}

.modals_box_info > .modals_box_info_btn3 {
  color: #FFFFFF;
  background-color: #07336C;
  border-radius: 30px;
}

.modals_box_card_container {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
}

.modals_box_card {
  display: flex;
  flex-wrap: wrap;
  width: 100%
}

.modals_box_card_item1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modals_box_card_item1_title {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.modals_box_card_item1_title > h4 {
  margin-right: 10px;
}

.modals_box_card_item1 > p {
  width: 100%;
}

.card_badge {
  border-radius: 50px;
  width: 34px;
  height: 34px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
}

.back_action {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.8;
}

.modals_box_card_box {
  width: 376px;
  height: 275px;
  background: #E8F2FE;
  border: 2px solid #FFFFFF;
  border-radius: 30px;
  padding: 30px;
}

/*========================== MOBILE =============================*/

@media screen and (max-width: 480px) {
  .modals_box {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .modals_box_card_container {
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .modals_box_field {
    padding: 20px;
    max-width: 480px;
  }

  .modals_box_header {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-right: 0;
    color: #07336C;
  }

  .modals_box_card {
    width: 100%;
    color: #07336C;
  }

  .modals_box_card_item1 {
    height: 100%;
  }

  .modals_box_card_box {
    width: 100%;
  }

  .modals_box_info {
    gap: 5px
  }

  .modals_box_header > button {
    align-self: end;
  }

  .modals_box_info > button {
    padding: 10px 7px;
  }
}