.redirect_btns_container {
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin: 40px auto 0;
  text-decoration: none;
}

.redirect_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  width: 100%;
  height: 140px;
  background: #fff;
  border-radius: 24px;
  outline: none;
  border: 2px solid #07336C;
  color: #07336C;
  cursor: pointer;
}

.redirect_btn > div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #07336C;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redirect_btn > div > svg > path {
  fill: #fff;
}

.redirect_btn:hover {
  background: #07336C;
  color: #fff;
}

.redirect_btn:hover > div {
  background-color: #fff;
}

.redirect_btn:hover > div > svg > path {
  fill: #07336C;
}

.redirect_btns_container > a {
  text-decoration: none;
  width: 50%;
}

/*===========================MOBILE=================================*/

@media screen and (max-width: 480px) {
  .redirect_btns_container {
    flex-direction: column;
    gap: 15px;
    margin: 15px 20px;
  }

  .redirect_btns_container > a {
    text-decoration: none;
    width: 100%;
  }

  .redirect_btn {
    height: 70px;
    padding: 20px;
    background: #07336C;
    color: #fff;
    border-radius: 18px;
  }

  .redirect_btn > div {
    width: 30px;
    height: 30px;
    background-color: #fff;
  }

  .redirect_btn > div > svg > path {
    fill: #07336C;
  }

  .redirect_btns_container_full_width {
    margin: 15px 0;
  }
}
