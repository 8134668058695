.latest_news {
  max-width: 1440px;
  width: calc(100% - 160px);
  margin: 123px auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.latest_news_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

.latest_news_container_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 50px;
  background: #E8F2FE;
  border-radius: 30px;
  gap: 40px;
  flex-direction: column;
}

.latest_news > h1 {
  margin-bottom: 67px;
}

.latest_news_container_item_photo {
  height:100%;
  width: 100%;
  /*border: 2px solid #07336C;*/
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.latest_news_container_item_text {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.latest_news_btn {
  width: 100%;
  height: 60px;
  background: #07336C;
  border-radius: 30px;
  cursor: pointer;
  color: #FFFFFF;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  animation: anim 0.1s ease;
}

.latest_news_btn:hover {
  color: #07336C;
  background: #E8F2FE;
}

.latest_news_container_date {
  width: 95px;
  height: 29px;
  background: #FFFFFF;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper {
  width: 100% !important;
  padding-top: 0 !important;
}

.foto_container {
  width: 100% !important;
  height: 100% !important;
}

/*============================= MOBILE ============================*/

@media screen and (max-width: 480px) {
  .latest_news {
    width: 100%;
    margin: 0 auto;
  }

  .latest_news_container {
    width: 100%;
    padding: 20px;
    gap: 15px;
  }

  .latest_news_container_item {
    padding: 20px;
    flex-direction: column;
  }

  .latest_news > h1 {
    margin-bottom: 40px;
  }

  .latest_news_container_item_photo {
    width: 100%;
  }

}