@keyframes begindrag {
    0% {
        transform: translateY(2px)
    }

    50% {
        transform: translateY(0px)
    }

    100% {
        transform: translateY(2px)
    }
}

@keyframes animationCards {
    0% {
        transform: translate(0px, 0px)
    }

    50% {
        transform: translate(-30px, -30px) scale(1.05)
    }

    100% {
        transform: translate(0px, 0px)
    }
}

.main_input {
    max-width: 1280px;
    margin: 0 auto;
    padding-bottom: 60px;
}

.tariff_title {
    color: #07336C;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    text-align: center;
    margin-bottom: 60px;
    padding-top: 60px;
}

.main_input_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #07336C;
    border-radius: 30px;
    width: 100%;
}

.main_poster_arrow {
    border-radius: 50%;
    background-color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_input_card {
    width: calc(100% - 160px);
    border-radius: 35px;
    display: flex;
}

.main_input_block {
    width: 615px;
    background-color: #E8F2FE;
    border-radius: 30px;
    margin-left: 105px;
}

.main_input_text_box {
    width: 665px;
    min-width: 482px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px
}

.main_input_text_box > p {
    color: #FFFFFF;
}

.main_input_block_flex {
    margin: 97px 72px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.main_input_text_left {
    margin-bottom: 45px;
    text-align: center;
}

.main_input_text_left_small {
    margin-top: 5px;
}

.main_input_block_field {
    padding: 20px 30px;
    gap: 10px;
    border: none;
    width: 470px;
    height: 59px;
    background: #FFFFFF;
    border-radius: 30px;
    outline: none;
}

.error {
    border: 1px solid red;
}

.main_input_block_field:active {
    border: none;
    outline: none;
}

.main_input_block_field_danger {
    margin-top: 20px;
    gap: 10px;
    border: none;
    width: 470px;
    height: 61px;
    background: #D62B1E;
    border-radius: 30px;
    cursor: pointer;
    color: white;
    transition: all 0.3s ease-in-out;
    animation: anim 0.1s ease;
}

.main_input_block_field_danger:hover {
    background: #EB766D;
}

.img_for_main {
    display: none;
}

.main_poster_container {
    position: relative;
    height: 458px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 84px 0 94px 75px;
    display: flex;
    background-color: #E8F2FE;
    border-radius: 30px;
    margin-bottom: 30px;
}

.main_poster_container_desc {
    width: 44%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.main_poster_container_desc > div {
    display: flex;
    justify-content: space-between;
}

.main_poster_container_cards {
    width: 50%;
    position: relative;
    transition: transform 0.5s;
}

.main_poster_container_card {
    position: absolute;
    top: 0;
    left: 0;
    width: 358px;
    height: 226px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    transition: transform 0.5s, opacity 0.3s;
    opacity: 1;
}

.main_poster_container_cards:hover {
    animation: 1s normal animationCards;
    animation-iteration-count: 1;
}

.main_poster_container_card:nth-child(1) {
    transition-delay: 100ms;
    transform: translate(78px, -34px);
    z-index: 4;
}

.main_poster_container_card:nth-child(2) {
    transition-delay: 200ms;
    transform: translate(158px, 26px);
    z-index: 3;
}

.main_poster_container_card:nth-child(3) {
    transition-delay: 300ms;
    transform: translate(238px, 86px);
    z-index: 2;
}

.main_poster_container_card:nth-child(4) {
    transition-delay: 400ms;
    transform: translate(318px, 146px);
    z-index: 1;
}

.firstPosition:nth-child(1), .firstPosition:nth-child(2), .firstPosition:nth-child(3), .firstPosition:nth-child(4) {
    transform: translate(318px, 146px);
    opacity: 0;
}

/* .main_poster_container_cards > div:hover {
  z-index: 10;
} */

.standart {
    background-image: url("../../images/cards/standart.png");
}

.start {
    background-image: url("../../images/cards/start.png");
}

.business {
    background-image: url("../../images/cards/business.png");
}

.corp {
    background-image: url("../../images/cards/corp.png");
}

.prime {
    background-image: url("../../images/cards/prime.png");
}

.lite {
    background-image: url("../../images/cards/lite.png");
}

.optima {
    background-image: url("../../images/cards/optima.png");
}

.premium {
    background-image: url("../../images/cards/premium.png");
}

.discount_card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #07336C;
    padding: 20px 36px;
    border-radius: 30px;
    width: 100%;
    min-height: 100px;
    max-width: 1280px;
    margin: 50px 80px;
}

.accordion_title {
    font-weight: 700 !important;
    font-size: 36px !important;
    line-height: 40px !important;
    letter-spacing: -1px !important;
    color: #fff !important;
    max-width: 98%;
    font-family: 'Inter', sans-serif !important;
}

.price_btn {
    color: #fff;
    background-color: #D62B1E;
    border-radius: 30px;
    padding: 8px 20px 10px 20px;
    font-weight: 500 !important;
    font-size: 26px !important;
    cursor: pointer;
    margin-left: 8px;
}

.price_btn:hover {
    background-color: #EB766D;
}

/*===========================MOBILE=================================*/

@media screen and (max-width: 480px) {
    .main_input {
        width: 100%;
        padding: 20px;
    }

    .main_input_flex {
        background: transparent;
        margin: 0;
        padding: 0;
    }

    .main_input_card {
        display: flex;
        flex-wrap: wrap;
        background: transparent;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .main_input_text_box {
        width: 100%;
        min-width: unset;
        background: #07336C;
        margin-bottom: 120px;
        border-radius: 30px;
        padding: 30px;
    }

    .main_input_text_box > p {
        text-align: center;
    }

    .main_input_block {
        width: 100%;
        margin: 0;
        padding: 40px 20px;
    }

    .main_input_block_flex {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .main_input_block_flex > input {
        width: 100%;
    }

    .main_input_text_box_big {
        width: 80%;
        margin: 0 auto;
    }

    .main_input_block_field_danger {
        width: 100%;
    }

    .main_input_text_left_small {
        color: #07336C;
    }

    .main_input_block_flex > .main_input_text_left {
        text-align: center;
        margin-bottom: 25px;
    }

    .img_for_main {
        display: block;
        width: 100%;
        border-radius: 30px;
        margin-bottom: 20px;
    }

    .main_poster_container {
        height: 560px;
        padding: 20px;
        align-items: center;
        flex-direction: column;
        margin: 0 20px;
        gap: 20px;
    }

    .main_poster_container > div {
        gap: 10px;
    }

    .main_poster_container > div > p {
        text-align: center;
        width: 100%;
    }

    .main_poster_container_cards {
        width: 100%;
        height: 100%;
    }

    .main_poster_container_card {
        width: 100%;
        overflow: hidden;
    }

    .main_poster_container_card:nth-child(1) {
        transform: translateY(0px);
        z-index: 4;
    }

    .main_poster_container_card:nth-child(2) {
        transform: translateY(60px);
        z-index: 3;
    }

    .main_poster_container_card:nth-child(3) {
        transform: translateY(120px);
        z-index: 2;
    }

    .main_poster_container_card:nth-child(4) {
        transform: translateY(180px);
        z-index: 1;
    }

    .firstPosition:nth-child(1), .firstPosition:nth-child(2), .firstPosition:nth-child(3), .firstPosition:nth-child(4) {
        transform: translateY(180px);
        opacity: 0;
    }

    .main_poster_container_desc {
        width: 100%;
    }

    .animated {
        animation: 3s infinite normal begindrag;
    }

    .main_poster_container_desc > div {
        display: flex;
        justify-content: center;
    }

    .tariff_title {
        font-size: 36px;
        line-height: 40px;
        padding: 40px 20px 0px 20px;
        margin-bottom: 20px;
    }

    .accordion_title {
        font-size: 26px !important;
        line-height: 30px !important;
        letter-spacing: -0.7px !important;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-break: break-word;
    }

    .discount_card {
        padding: 20px 36px;
        border-radius: 30px;
        margin: 0 20px;
        width: calc(100% - 40px);
    }

    .price_btn {
        display: block;
        font-weight: 500 !important;
        font-size: 26px !important;
        cursor: pointer;
        margin: 10px 0 0 0;
        white-space: nowrap;
        width: fit-content;
    }

    .price_btn:hover {
        background-color: #EB766D;
    }
}

