.App {
  max-width: 1440px;
  margin: 0 auto;
  width: 1440px;
}

@media screen and (max-width: 480px) {
  .App {
    width: 100%;
  }
}
