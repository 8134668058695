.text_info_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  min-width: 280px;
  margin: 45px auto;
  gap: 60px;
  padding: 60px 0 20px;
}

.text_info_center_container {
  display: flex;
  gap: 20px;
}

.text_info_center_container > h4 {
  max-width: 400px;
}

.text_info_right_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 840px;
}

.text_info_bottom_container {
  display: flex;
  gap: 40px;
}

.text_info_bottom_container > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 10px;
}

@media screen and (max-width: 480px) {
  .text_info_container {
    padding: 60px 20px 20px;
    align-items: center;
    gap: 20px;
  }

  .text_info_container * {
    text-align: center;
  }


  .text_info_container > h2 {
    margin-bottom: 20px;
  }

  .text_info_center_container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .text_info_bottom_container {
    flex-wrap: wrap;
    gap: 20px;
  }
}