* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    margin: 55px 0;
    height: 100%;
}

.flex_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 160px);
    margin: 0 auto;
    height: 30px;
    max-height: 30px;
}

.flex_header img {
    cursor: pointer;
}

.header_icons {
    height: 33px;
}

.flex_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
}

.video_icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #E8F2FE;
    transition: all 0.5s ease-in-out;
    animation: anim 0.3s ease;
}

.video_icon:hover {
    background-color: #07336C;
}

.video_icon > svg {
    fill: #07336C;
    width: 20px;
    height: 20px;
}

.video_icon:hover > svg > path {
    fill: white;
    width: 20px;
    height: 20px;
}

.flex_info > img {
    margin-left: 20px;
}

.flex_info > a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #07336C;
    gap: 10px;
}

.deep_menu {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.deep_menu > a > button {
    background: none;
    border-radius: 30px;
    height: 39px;
    border: none;
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
    object-fit: fill;
    width: 100%;
    cursor: pointer;
    color: #07336C;
    transition: all 0.4s ease-in-out;
    animation: anim 0.2s ease;
}

.deep_menu > a > button:hover {
    background: #E8F2FE;
}

.header svg:hover {
    fill: #07336C;
}

.header .flex_info > a:hover svg {
    fill: #07336C;
}

.header .flex_info > a:hover svg > path {
    fill: #FFFFFF;
}

.header svg:hover path {
    fill: white;
}

.deep_menu_link {
    display: block;
}

.picker {
    padding: 5px 5px;
    border-radius: 30px;
    background-color: #07336C;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.picker > p {
    padding: 0 10px;
}

.picker > p.active {
    padding: 8px 15px;
    background-color: #fff;
    color: #07336C;
    border-radius: 30px;

}

.logo {
    width: 180px;
}

.logos {
    display: none;
}

/*==================== MOBILE ===================*/

@media screen and (max-width: 480px) {
    .header {
        width: 100%;
        margin: 25px 0 18px;
    }

    .logo {
        width: 85px;
    }

    .flex_header {
        width: 100%;
        padding: 25px;
        margin-top: 0;
    }

    .flex_info > a {
        display: none;
    }

    .deep_menu {
        display: none;
    }

    .logos {
        display: flex;
        gap: 15px;
        margin-left: auto;
    }

    .logos * {
        width: 50px;
        height: 50px;
    }

    .logos > .video_icon > svg {
        fill: #07336C;
        width: 30px;
        height: 30px;
    }
}