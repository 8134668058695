.container {
    max-width: 100%;
    margin-top: 140px;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
}

.title {
    text-align: center;
    margin-bottom: 60px;
}

.rows {
    display: flex;
    flex-direction: column;
    border-radius: 90px;
    overflow: hidden;
    border: 1px solid #07336C;
}

.row {
    display: flex;
    height: auto;
}

.img_left {
    background-color: #D1E4FC;
    flex: 1;
    height: 100%;
    position: relative;
}

.img_right {
    background-color: #D1E4FC;
    flex: 1;
    height: 100%;
    position: relative;
}

.img_right > img, .img_left > img {
    width: 100%;
}

.img_right > div, .img_left > div {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background-color: #07336C;
    display: flex;
    flex-direction: column;
    padding: 20px 70px;
    gap: 5px;
    color: #fff;
}

.img_right > div {
    border-radius: 0 0 0 90px;
    align-items: flex-end;
}

.img_left > div {
    border-radius: 0 0 90px 0;
}


.card_left {
    background-color: #07336C;
    flex: 1;
    height: 100%;
}

.card_left > div {
    border-radius: 90px 0 0 90px;
}

.card_right {
    background-color: #07336C;
    flex: 1;
    height: 100%;
}

.card_right > div {
    border-radius: 0 90px 90px 0;
}

.card_right, .card_left {
    height: auto;
}

.card_right > div, .card_left > div {
    width: 100%;
    height: 100%;
    background-color: #D1E4FC;
    padding: 0 90px;
    white-space: break-spaces;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name {
    font-size: 29px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
}

.description {
    font-size: 26px !important;
    font-weight: 200 !important;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .container {
        max-width: 100%;
        margin-top: 100px;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .rows {
        display: flex;
        flex-direction: column;
        border-radius: 60px;
        overflow: hidden;
        border: none;
        gap: 20px;
    }

    .row {
        display: flex;
        flex-direction: column;
        height: auto;
        border-radius: 60px;
        overflow: hidden;
        border: 1px solid #07336C;
    }

    .img {
        background-color: #07336C;
    }

    .img > img {
        width: 100%;
        object-fit: contain;
        border-radius: 60px;
    }

    .img > div {
        width: 100%;
        background-color: #07336C;
        display: flex;
        flex-direction: column;
        padding: 10px 30px;
        gap: 5px;
        color: #fff;
    }

    .name {
        font-size: 18px !important;
        font-weight: 500 !important;
        text-transform: uppercase;
    }

    .description {
        font-size: 18px !important;
        font-weight: 200 !important;
    }

    .card {
        background-color: #D1E4FC;
        padding: 20px;
        white-space: break-spaces;
    }
}
