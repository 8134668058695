.container {
    max-width: 100%;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    justify-content: space-between;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    gap: 30px;
    position: relative;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    width: 615px;
    height: 615px;
    border-radius: 30px;
    position: relative;
    background-color: #07336C;
}

.image {
    width: 800px;
    height: 800px;
    position: relative;
    top: -65px;
    right: 120px;
    object-fit: contain;
}

.icon {
    width: 90px;
    height: 166px;
    position: absolute;
    top: 70px;
    left: 0px;
    object-fit: contain;
    z-index: -1;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .container {
        max-width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0px;
        flex-direction: column-reverse;
        gap: 40px;
    }

    .wrapper {
        width: 100%;
        height: calc(100vw - 74px);
    }

    .content {
        width: 100%;
    }

    .content > * {
        text-align: center;
    }

    .image {
        width: 100vw;
        position: relative;
        top: -220px;
        right: 40px;
    }

    .icon {
        width: 39px;
        height: 71px;
        position: absolute;
        top: -20px;
        left: 70px;
        object-fit: contain;
        z-index: -1;
    }
}
