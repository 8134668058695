.back_action {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
}

.contacts {
    display: flex;
    max-width: 1280px;
    padding: 50px;
    background-color: #07336C;
    border-radius: 30px;
    margin: 190px 80px 0 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.contacts_first_flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #E8F2FE;
    border-radius: 30px;
    padding: 50px;
    gap: 50px;
    position: relative;
    z-index: 100;
}

.contacts_second_flex {
    width: 100%;
    border-radius: 30px;
}

.contacts_first_flex_box1 {
    display: flex;
    justify-content: space-between;
}

.contacts_first_flex_box1 > h1 {
    min-width: 70%;
    min-width: 715px;
    width: 100%;
}

.contacts_first_flex_box1 > h1::first-letter {
    text-transform: capitalize;
}

.contacts_first_flex_box1 > p {
    width: 315px;
}

.contacts_first_flex_inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.contacts_first_flex_inputs_info {
    width: 334px;
    height: 59px;
    background: #FFFFFF;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 30px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 1;
    border: none;
    outline: none;
}

.contacts_first_flex_inputs_info:active {
    border: none;
    outline: none;
}

.contacts_first_flex_inputs_danger {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 10px;
    width: 334px;
    height: 61px;
    border: none;
    outline: none;
    background: #D62B1E;
    border-radius: 30px;
    flex: none;
    order: 2;
    flex-grow: 1;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    animation: anim 0.1s ease;
}

.contacts_first_flex_inputs > .contacts_first_flex_inputs_danger {
    color: #FFFFFF;
}

.close_btn {
    position: absolute;
    top: 15px;
    right: 20px;
    border: none;
    cursor: pointer;
    background: transparent;
}

.contacts_first_flex_inputs_danger:active {
    border: none;
    outline: none;
}

.contacts_first_flex_inputs_danger:hover {
    background: #EB766D;
}

.contacts_first_flex > p {
    margin-top: -26px;
    text-align: start;
}

.contacts_second_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contacts_second_flex > div > a > span {
    color: #E8F2FE;
    text-decoration: none;
}

.error {
    border: 1px solid red;
}

.contacts_second_flex > p {
    color: #FFFFFF;
    margin-left: 230px;
}

.flex_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex_info > div > a {
    text-decoration: none;
    color: #FFFFFF;
}

.flex_info > div > svg {
    height: 30px;
    cursor: pointer;
}

.flex_info > div {
    display: flex;
    align-items: center;
}

.flex_info > div:hover > svg {
    fill: #D62B1E;
    color: #FFFFFF;
}

.flex_info > div:hover > svg > path {
    fill: #FFFFFF;
}

.video_icon {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 14px;
}

.video_icon > div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #E8F2FE;
    transition: all 0.5s ease-in-out;
    animation: anim 0.3s ease;
}

.video_icon:hover > div {
    background-color: #D62B1E;
}

.video_icon > div > svg {
    fill: #07336C;
    width: 20px;
    height: 20px;
}

.video_icon:hover > div > svg > path {
    fill: #fff;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .contacts {
        width: 100%;
        margin: 0 auto;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        gap: 0;
        padding: 20px;
        justify-content: start;
    }

    .contacts_first_flex {
        padding: 40px 20px;
        width: 100%;
    }

    .contacts_first_flex_box1 > p {
        display: none;
    }

    .contacts_first_flex_box1 > h1 {
        max-width: unset;
        min-width: unset;
        width: 100%;
        text-align: center;
    }

    .contacts_first_flex_inputs {
        flex-wrap: wrap;
        width: 100%;
    }

    .contacts_first_flex_inputs > input {
        width: 100%;
    }

    .contacts_first_flex_inputs > button {
        width: 100%;
        margin-top: 15px;
    }

    .contacts_first_flex > p {
        text-align: center;
    }

    .contacts_second_flex {
        flex-wrap: wrap;
        width: calc(100% - 40px);
    }

    .contacts_second_flex > p {
        display: none;
    }

    .logo_mfc {
        display: none;
    }

    .flex_info {
        margin-top: 40px;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    .flex_info > svg {
        height: 60px;
        cursor: pointer;
    }
}