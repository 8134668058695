.container {
    max-width: 100%;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
}

.frame {
    height: calc((1440px - 160px) / 1.7);
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .container {
        max-width: 100%;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .frame {
        height: calc((100vw - 80px) / 1.7);
    }
}
