.modal_box {
  background: #07336C;
  height: 100%;
  padding: 32px 20px 20px 20px;
}

.logos {
  display: flex;
  justify-content: space-between;
}

.logo_white {
  width: 85px;
  height: 46px;
  object-fit: contain;
}

.close_flex {
  display: flex;
  gap: 15px;
  align-items: center;
}

.close_flex  * {
  height: 50px;
  width: 50px;
}

.deep_menu {
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  gap:20px;
  transition: all 0.5s ease-in-out;
  animation: anim 0.3s ease;
}

.deep_menu button{
  width: 100%;
  height: 59px;
  border-radius: 30px;
  color: #07336C;
  outline: none;
  background: #E8F2FE;
  border: none;
}

a > .btn_danger {
  background: #D62B1E;
  color: #FFFFFF;
}

.video_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #E8F2FE;
  transition: all 0.5s ease-in-out;
  animation: anim 0.3s ease;
}

.video_icon:hover {
  background-color: #07336C;
}

.video_icon > svg {
  fill: #07336C;
  width: 35px;
  height: 35px;
}

.video_icon:hover > svg > path {
  fill: white;
  width: 35px;
  height: 35px;
}
