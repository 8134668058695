.services_container {
    max-width: 1440px;
}

.services_header {
    padding: 20px 90px 0 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.services_top {
    padding: 20px 90px 0 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.anchor {
    position: absolute;
    scroll-margin-top: 30vh;
}

.accordion {
    padding: 50px 80px;
    margin-bottom: -160px;
    background: transparent;
}

.services_block {
    padding: 55px;
    margin: 125px 25px;
}

.accordion_options {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #07336C !important;
    width: 85%;
    white-space: pre-line;
}

.accordion_title_wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.accordion_title_price {
    display: flex;
    flex: 1;
    color: #D62B1E;
    font-weight: 700 !important;
    font-size: 20px !important;
    margin-top: 10px;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.accordion_title_price_card {
    padding: 8px 14px 9px 14px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    transition: box-shadow 0.3s;
}

.accordion_title_price_card:hover {
    box-shadow: 2px 2px 5px black;
}

.accordion_title {
    font-weight: 700 !important;
    font-size: 36px !important;
    line-height: 40px !important;
    letter-spacing: -1px !important;
    color: #07336C !important;
    max-width: 98%;
}

.accordion_title::first-letter {
    text-transform: capitalize;
}

.services_title_secondary {
    color: #D62B1E;
}

.search_input {
    max-width: 400px;
    width: 100%;
}

.services_block > h1 {
    margin-left: 5%;
    margin-bottom: 80px;
}

.paper {
    border: 2px solid #07336C;
    box-shadow: none !important;
    border-radius: 30px !important;
    padding: 0;
    max-width: 400px;
    outline: none;
    width: 100%;
}

.price_div {
    background-color: #D62B1E;
    border-radius: 30px;
    border: 2px solid #D62B1E;
    padding: 7px 15px;
    width: 150px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    min-width: 150px;
    align-self: flex-start;
}

.accordion_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
}

.accordion_list > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.accordion_list > div > p::first-letter {
    text-transform: capitalize;
}

.option_dots {
    flex: 1;
    overflow: hidden;
}

.option_price {
    margin-left: auto !important;
}

.accordion_tags {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.accordion_tags > span {
    width: fit-content;
}

.search_btn {
    max-width: 150px;
    width: 100%;
    height: 50px;
    border-radius: 30px;
    outline: none;
    background: #07336C;
    border: none;
    color: #FFFFFF;
    margin: 0;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    animation: anim 0.2s ease;
}

.search_btn:hover {
    background: #E8F2FE;
    color: #07336C;
}

[aria-labelledby="panel1d-header"] {
    margin-top: 20px;
}

@media screen and (max-width: 480px) {
    .sumIcons {
        margin: 'auto 0';
    }

    .services_header {
        flex-wrap: wrap;
        margin: 0;
        padding: 5%;
    }

    .services_top {
        flex-wrap: wrap;
        margin: 0;
        padding: 5%;
    }

    .services_title {
        margin: 0 auto;
        text-align: center;
    }

    .services_title_secondary {
        margin: 0 auto;
        text-align: center;
    }

    .services_top > h1 {
        margin-top: 20px;
    }

    .search_input {
        margin-top: 30px;
        height: 40px;
    }

    .search_btn {
        height: 40px;
        width: 120px;
    }

    .accordion {
        padding: 20px;
        margin-bottom: 0px;
    }

    .accordion_title {
        font-size: 26px !important;
        line-height: 30px !important;
        letter-spacing: -0.7px !important;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        word-break: break-word;
    }

    .accordion_options {
        font-size: 18px !important;
        line-height: 21px !important;
        letter-spacing: -0.003em !important;
        width: 100%;
        text-align: center;
        word-break: break-word;
    }

    [aria-labelledby="panel1d-header"] {
        margin-top: 20px;
    }

    .price_div {
        width: 100%;
        text-align: center;
    }

    .accordion_list > div {
        flex-direction: column;
    }

    .accordion_list > div > p {
        text-align: center;
    }

    .anchor {
        scroll-margin-top: 10vh;
    }

    .option_dots {
        display: none;
    }

    .option_price {
        margin-left: 0 !important;
    }
}

