.track_changes {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

a {
    text-decoration: unset;
}

.track_changes_container {
    max-width: 1280px;
    height: 100%;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1280px;
    margin-left: 80px;
}

.track_changes_container_box {
    width: 615px;
    height: 615px;
    background: #07336C;
    border-radius: 30px;
    position: relative;
}

.track_changes_container_box > img {
    width: 710px;
    height: 508px;
    position: absolute;
    top: 10%;
    left: -14.7%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.track_changes_container_text {
    width: 512px;
    margin-left: 75px;
}

.track_changes_container_text_small {
    margin-top: 30px;
    width: 440px;
}


/*================================ MOBILE ================================*/

@media screen and (max-width: 480px) {
    .track_changes {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .track_changes_container {
        width: calc(100% - 40px);
        flex-direction: column-reverse;
        background: #07336C;
        border-radius: 30px;
        margin: 40px 20px 120px 20px;
    }

    .track_changes_container_text {
        width: 60%;
        padding: 0;
        margin: 0;
    }

    .track_changes_container_text > p {
        width: 100%;
        text-align: center;
        color: #E8F2FE;
        margin: 0 auto;
    }

    .track_changes_container_text > h6 {
        width: 100%;
        text-align: center;
        color: #FFFFFF;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .track_changes_container_box {
        width: 100%;
        background: transparent;
        height: 100%;
        margin-bottom: 40px;
    }

    .track_changes_container_box > img {
        width: 90%;
        height: 90%;
        position: static;
        transform: matrix(1, 0, 0, 1, 0, 0);
        margin-left: -3px;
    }
}

@media screen and (max-width: 400px) {
    .track_changes_container_text > p {
        font-size: 26px;
    }

    .track_changes_container_text > h6 {
        font-size: 14px;
    }

}
