.container {
    max-width: 100%;
    margin-top: 140px;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    box-sizing: border-box;
}

.wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 30px;
    position: relative;
    background-color: #E8F2FE;
    margin-bottom: 140px;
}

.content {
    padding: 103px 10px 103px 120px;
}

.image {
    width: 802px;
    position: absolute;
    top: -20px;
    right: -100px;
}

.title {
    padding-bottom: 15px;
}

.description {
    padding-bottom: 45px;
    padding-left: 25px;
    width: 536px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.button {
    cursor: pointer;
    border: none;
    border-radius: 30px;
    padding: 5px 50px 5px 50px;
    width: 290px;
    height: 60px;
    background-color: #07336C;
    color: #FFFFFF;
}

.button:hover {
    background-color: #FFFFFF;
    color: #07336C;
}

.info_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.8;
}

.info_block_link {
    display: flex;
    align-items: center;
    gap: 10px;
}

.info_block_link > img {
    margin-left: 20px;
}

.info_block_link > a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #07336C;
    gap: 10px;
}

.info_block_link > a:hover svg {
    fill: #07336C;
}

.info_block_link > a:hover svg > path {
    fill: #FFFFFF;
}

.info_block_text {
    width: 740px;
    text-align: end;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .container {
        max-width: 100%;
        margin-top: 140px;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
    }

    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 30px;
        position: relative;
        background-color: #E8F2FE;
        margin-bottom: 20px;
    }

    .content {
        padding: 100px 30px 40px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .image {
        width: 236px;
        position: absolute;
        top: -125px;
        left: calc(50% - 118px);
    }

    .title {
        padding-bottom: 20px;
    }

    .description {
        width: 100%;
        padding-bottom: 40px;
    }

    .button {
        cursor: pointer;
        border: none;
        border-radius: 30px;
        padding: 5px 50px 5px 50px;
        width: 290px;
        height: 60px;
        background-color: #07336C;
        color: #FFFFFF;
    }

    .button:hover {
        background-color: #FFFFFF;
        color: #07336C;
    }

    .info_block {
        flex-direction: column;
        gap: 10px;
    }

    .info_block_link {
        flex-direction: column-reverse;
    }

    .info_block_link > a > span {
        display: none;
    }

    .info_block_link > a > svg {
        width: 60px;
        height: 60px;
    }

    .info_block_text {
        text-align: center;
        width: 100%;
    }
}
