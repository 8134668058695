.container {
    max-width: 100%;
    margin-top: 140px;
    height: 100%;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.title {
    margin-bottom: 66px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 40px;
}

.card {
    padding: 50px 50px 50px 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 620px;
    height: 266px;
    background-color: #E8F2FE;
    border-radius: 30px;
}

.count {
    background-color: #FFFFFF;
    border-radius: 30px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 15px;
}

.buttons > a {
    cursor: pointer;
}

.button {
    background-color: #D62B1E;
    color: #fff;
    padding: 10px 20px;
    border-radius: 30px;
    width: fit-content;
}

.button:hover {
    background-color: #fff;
    color: #D62B1E;
}

.info {
    align-self: flex-end;
    width: 440px;
    margin-top: 10px;
    margin-right: 180px;
}

a {
    text-decoration: unset;
}

.text_icon {
    width: 53px;
    height: 97px;
    position: absolute;
    top: -28px;
    left: 460px;
    object-fit: contain;
    z-index: -1;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
    .container {
        max-width: 100%;
        margin-top: 140px;
        height: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .title {
        margin-bottom: 20px;
    }


    .cards {
        gap: 10px;
    }

    .card {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 30px;
    }

    .card > p {
        text-align: center;
    }

    .buttons {
        margin-top: 10px;
    }

    .info {
        align-self: center;
        width: 95%;
        margin-top: 10px;
        margin-right: 0;
        text-align: center;
    }

    .text_icon {
        width: 30px;
        height: 71px;
        top: -20px;
        left: 36px;
    }
}
