.cards_container {
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 30px;
    gap: 40px;
    color: #FFFFFF;
}

.card {
    position: relative;;
    border-radius: 30px;
    flex: 1;
    overflow: hidden;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
}

.anchor {
    position: absolute;
    top: -200px;
}

.card:hover {
    transform: translateY(-40px);
}

.cardActive {
    transform: translateY(-40px);
}

.card_header {
    padding: 20px;
}

.card_title {
    font-size: 30px;
    font-weight: 500;
    line-height: 46px;
    text-align: center;
}

.card_subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
}

.card_content {
    padding: 20px;
    background-color: #38414A;
    display: flex;
    flex-direction: column;
}

.card_badge {
    padding: 14px;
    border-radius: 30px;
    border: 1px solid #fff;
    gap: 10px;
    text-align: center;
    width: 180px;
    align-self: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: box-shadow 0.3s;
}

.card_badge:hover {
    box-shadow: 0px 1px 5px #fff;
}

.card_services {
    padding: 0px 10px;
}

.card_service {
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}

.card_service_plus {
    font-size: 20px;
    font-weight: 500;
    margin-right: 6px;
}

.card_footer {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    border-top: 1px solid #FFFFFF;
    padding-top: 20px;
    margin-top: auto;
    margin-top: 20px;
}

.card:nth-child(1) .card_header {
    background: linear-gradient(180deg, #E6E6E7 0%, #8F9698 58.51%);
}

.card:nth-child(2) .card_header {
    background: linear-gradient(180deg, #E7DBC2 0%, #7C715C 58.51%);
}

.card:nth-child(3) .card_header {
    background: linear-gradient(180deg, #397C87 0%, #164143 58.51%);
}

.card:nth-child(4) .card_header {
    background: linear-gradient(180deg, #757679 0%, #282B2E 58.51%);
}

/*.card:nth-child(1) .card_underfooter {*/
/*    background: linear-gradient(180deg, #E6E6E7 0%, #8F9698 58.51%);*/
/*}*/

/*.card:nth-child(2) .card_underfooter {*/
/*    background: linear-gradient(180deg, #E7DBC2 0%, #7C715C 58.51%);*/
/*}*/

/*.card:nth-child(3) .card_underfooter {*/
/*    background: linear-gradient(180deg, #397C87 0%, #164143 58.51%);*/
/*}*/

/*.card:nth-child(4) .card_underfooter {*/
/*    background: linear-gradient(180deg, #757679 0%, #282B2E 58.51%);*/
/*}*/

.card_underfooter {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    border-radius: 0px 0 30px 30px;
    min-height: 60px;
    cursor: pointer;
    background-color: #38414A;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.price_btn {
    color: #fff;
    background-color: #D62B1E;
    border-radius: 30px;
    padding: 8px 20px 10px 20px;
    font-weight: 500 !important;
    font-size: 26px !important;
    cursor: pointer;
}

.price_btn:hover {
    background-color: #EB766D;
}

/*=======================MOBILE========================*/

@media screen and (max-width: 480px) {
    .cards_container {
        flex-direction: column;
        padding: 20px;
        height: auto;
    }

    .card {
        scroll-margin-top: 10vh;
    }

    .card:hover {
        transform: unset;
    }

    .cardActive {
        transform: unset;
    }

    .anchor {
        top: -50px;
    }

    .card_underfooter {
        min-height: 80px;
    }

    .price_btn {
        display: block;
        font-weight: 500 !important;
        font-size: 26px !important;
        cursor: pointer;
        margin: 10px 0 0 0;
        white-space: nowrap;
        width: fit-content;
    }

    .price_btn:hover {
        background-color: #EB766D;
    }
}


