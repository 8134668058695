.triple_request {
  max-width: 100%;
  margin-top: 140px;
  height: 100%;
  padding-left: 80px;
  padding-right: 80px;
}

.triple_request_flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
  width: 100%;
}

.triple_request_box1 {
  position: relative;
}

.triple_request_box1 > img {
  width: 730px;
  position: absolute;
  top: 34%;
  left: 43%
}

.triple_request_box2 {
  position: relative;
}

.triple_request_box2 > img {
  width: 680px;
  height: 486px;
  position: absolute;
  top: 5%;
  left: -0.3%;
}

.triple_request_box2 > div {
  position: absolute;
  top: 0;
  left: 50%;
}

.triple_request_flex_btn {
  color: white;
  width: 220px;
  height: 59px;
  background: #D62B1E;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in-out;
  animation: anim 0.1s ease;
}

.triple_request_flex_btn_dark {
  color: white;
  width: 220px;
  height: 59px;
  background: #07336C;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  margin-top: 35px;
  transition: all 0.3s ease-in-out;
  animation: anim 0.1s ease;
}

.triple_request_flex_btn_dark:hover {
  background: #FFFFFF;
  color: #07336C;
}

.triple_request_flex_btn > a {
  color: #FFFFFF;
  text-decoration: none;
}

.triple_request_box1 > div > button {
  color: #FFFFFF;
  cursor: pointer;
}

.triple_request_box1_little_text {
  text-decoration: none !important;
  text-transform: none !important;
}

.triple_request_box1_little {
  display: flex;
  flex-direction: column;
  width: 350px;
  justify-content: center;
  margin-top: 109px;
  margin-left: 120px;
  gap: 15px
}

.triple_request_box2_little {
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  margin-top: 80px;
  margin-left: 120px;
  gap: 15px
}

.triple_request_box2_little > button:hover {
  background: #FFFFFF;
  color: #07336C;
}

.triple_request_box3 {
  position: relative;
}

.triple_request_box3 > img {
  position: absolute;
  width: 725px;
  height: 539px;
  top: 2%;
  left: 45%;
}

.triple_request_box1_little > a {
  margin-top: 30px;
  text-decoration-line: none;
  cursor: pointer;
}

.triple_request_box1_little > a:hover {
  text-decoration-line: underline;
}

.triple_request_flex_btn:hover {
  background: #FFFFFF;
  color: #D62B1E;
}

.triple_request_box2_little > button {
  background: #07336C;
  color: #FFFFFF;
}

/*============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
  .triple_request {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    margin-bottom: 120px;
    margin-top: 100px;
  }

  .triple_request_flex {
    gap: 15px;
  }

  .triple_request_box1_little, .triple_request_box2_little {
    margin: 0;
    padding: 30px;
    position: relative;
    width: 100%;
  }

  .triple_request_box1 > img {
    max-width: 300px;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .triple_request_box1_title {
    width: 100%;
    padding-top: 220px;
  }

  .triple_request_box1_little > a {
    margin-top: 15px;
  }

  .triple_request_box2 {
    height: 100%;
  }

  .triple_request_box2 > div {
    position: static;
  }

  .triple_request_box2 > img {
    max-width: 360px;
    width: 100%;
    max-height: 235px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
  }

  .triple_request_flex_btn_dark {
    margin-top: 15px;
    width: 164px;
    height: 48px;
  }

  .triple_request_box3 > img {
    position: absolute;
    width: 250px;
    height: 200px;
    top: 20px;
    left: 90px;
    margin-left: 10px;
  }

  .triple_request_flex_btn {
    width: 164px;
    height: 48px;
  }
}

@media screen and (max-width: 360px) {
  .triple_request_box3 > img {
    left: 20px;
  }

  .triple_request_box1 > img {
    left: 20px;
    max-width: 260px;
  }
}
