.footer {
  height: 100%;
  width: 1280px;
  margin: 0 auto;
}

.footer_container {
  height: 100px;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_container_first {
  display: flex;
  margin-left: 10px;
  justify-content: start;
  padding-right: 10px;
  width: 100%;
  max-width: 680px;
}

.footer_container_first > button {
  height: 39px;
  border-radius: 30px;
  border: none;
  width: fit-content;
  background-color: white;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  animation: anim 0.3s ease;
}

.footer_container_first > button:hover {
  background: #D1E4FC;
}

.footer_container_first > button > a {
  color: #07336C;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.footer_container_second {
  display: flex;
  gap: 15px;
  text-decoration: none;
}

.footer_container_second > a {
  opacity: 0.5;
  cursor: pointer;
  text-decoration: none;
}

.footer_container_second > a:active {
  text-decoration: none;
  color: #07336C;
}

.footer_container_second > a:visited {
  text-decoration: none;
  color: #07336C;
}

.footer_container_second > a:hover {
  opacity: 1;
}

.back_btn {
  display: block;
  width: 100%;
  background: #D1E4FC;
  border-radius: 30px;
  outline: none;
  border: none;
  height: 90px;
}

.back_btn_container {
  border: 30px;
}

.btn_link {
  text-decoration: none;
}

.btn_link:active {
  color: #07336C;
}

.btn_link:visited {
  color: #07336C;
}

/*=============================== MOBILE =================================*/

@media screen and (max-width: 480px) {
  .footer {
    width: 100%;
    padding: 50px 0 50px 0;
    margin: 0 auto;
    background: #07336C;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .footer_container {
    margin-top: 0;
  }

  .footer_container_first {
    display: none;
    width: 100%;
  }

  .footer_container_second {
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }

  .footer_container_second > a {
    width: 100%;
    color: #D1E4FC;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    opacity: 1;
    margin-bottom: 10px;
  }
  .footer_container_second > a:active {
    text-decoration: none;
    color: #fff;
  }

  .footer_container_second > a:visited {
    text-decoration: none;
    color: #fff;
  }

}