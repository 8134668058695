html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #07336C;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
    fill: #E8F2FE;
    transition: all 0.5s ease-in-out;
    animation: anim 0.3s ease;
}

svg > path {
    fill: #07336C;
}

.h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 74px;
    letter-spacing: -3px;
}

.h1_des {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.6px;
}

.h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: -1.5px;
}

.h2_secondary {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: -1.5px;
}

.h2_des {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.3px;
}

.h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1px;
}

.h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.6px;
}

.h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.6px;
}

.h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.003em;
}

.text, .text_lg {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

.btn_title, .btn_title.MuiButton-root {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.2px;
    text-transform: none;
}

.text_large {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.8px;
}

.text_small {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.text_xsmall {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
}

.text_news {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.2px;
}

.div_box {
    max-width: 620px;
    min-width: 280px;
    width: 100%;
    border-radius: 30px;
}

.boxes {
    width: 100%;
    height: 480px;
    background-color: #E8F2FE;
    border-radius: 30px;
}

.text_large-mob {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
}

.btn-disabled {
    opacity: 0.5;
}

.start, .prime {
    background: linear-gradient(180deg, #E6E6E7 0%, #8F9698 58.51%);
}

.standart, .lite {
    background: linear-gradient(180deg, #E7DBC2 0%, #7C715C 58.51%);
}

.business, .optima {
    background: linear-gradient(180deg, #397C87 0%, #164143 58.51%);
}

.corp, .premium {
    background: linear-gradient(180deg, #757679 0%, #282B2E 58.51%);
}

@media screen and (max-width: 480px) {
    .boxes {
        height: 100%;
    }

    .div_box {
        background-position-x: center;
    }

    .h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 36px;
        letter-spacing: -1px;
    }

    .h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        letter-spacing: -1.2px;
    }

    .h2_secondary {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: -1.2px;
    }

    .h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        letter-spacing: -0.7px;
    }

    .h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.4px;
    }

    .h1_des {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.4px;
    }

    .h2_des {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.1px;
    }

    .btn_title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    }

    .text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    .text_large {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.4px;
    }
}

@media screen and (max-width: 360px) {
    .h1 {
        font-size: 26px;
    }
}

.MuiAccordion-root {
    border: none !important;
    outline: none !important;
    background: #E8F2FE !important;
    margin-bottom: 20px !important;
    border-radius: 30px !important;
    padding: 20px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiDialog-paperFullScreen {
    background-color: transparent;
}

@media screen and (max-width: 480px) {
    .MuiAccordion {
        justify-content: start;
        margin-bottom: 10px;
        padding: 10px;
    }
}